import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  CircularProgress,
  Box, // 🔹 追加
} from "@mui/material";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  Timestamp,
  query,
  orderBy,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../api/firebase";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // ← 🔥 ここを修正

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
// import { useSnackbar } from 'notistack';

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import FileCopyIcon from "@mui/icons-material/FileCopy"; // 🔥 コピーアイコンをインポート

const FilterGroupActivityUsers = () => {
  const [userList, setUserList] = useState([]);
  const [groupNames, setGroupNames] = useState({});
  const [loading, setLoading] = useState(false);
  const [listHeight, setListHeight] = useState(500);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false); // 🔹 ローディング状態
  const [selectedGroupId, setSelectedGroupId] = useState(null); // 🔹 選択されたグループ
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]); // 🔹 選択中のグループに追加されたユーザー

  const [addedUsers, setAddedUsers] = useState({});

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentEditingGroupId, setCurrentEditingGroupId] = useState(null);

  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [prefectureOptions, setPrefectureOptions] = useState([]);

  const [prefectureStats, setPrefectureStats] = useState({});


  const [bulkDialogOpen, setBulkDialogOpen] = useState(false);
  const [bulkPrefecture, setBulkPrefecture] = useState("");
  const [bulkStart, setBulkStart] = useState("");
  const [bulkEnd, setBulkEnd] = useState("");
  

  // コンポーネントの先頭でuseSnackbarを宣言
  // const { enqueueSnackbar } = useSnackbar();

  // 🔹 グループをクリックしたときにフォーカスを設定
  const handleGroupClick = (groupId) => {
    setSelectedGroupId(groupId);
    setSelectedGroupUsers([]); // 別のグループを選んだらリセット
  };

  // 画面サイズに応じてリストの高さを調整
  useEffect(() => {
    const updateHeight = () => {
      const headerHeight = 100; // タイトル・ボタンの高さ（適宜調整）
      const padding = 20; // 余白
      setListHeight(window.innerHeight - headerHeight - padding);
    };

    updateHeight(); // 初回実行
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // グループごとに色を割り当てる関数
  const getGroupColor = (groupId) => {
    const colors = [
      "#FFDDC1",
      "#FFABAB",
      "#FFC3A0",
      "#D5AAFF",
      "#85E3FF",
      "#B9FBC0",
      "#AFCBFF",
      "#FFD3B5",
      "#FF9A8B",
      "#A8D8EA",
    ];
    return colors[
      Math.abs(
        groupId.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0)
      ) % colors.length
    ];
  };

  // `groupId` に対応する `グループ名` を取得
  const fetchGroupNames = async (groupIds) => {
    const groupNamesMap = {};
    const groupPromises = groupIds.map(async (groupId) => {
      try {
        const groupRef = doc(db, "groups", groupId);
        const groupSnap = await getDoc(groupRef);
        if (groupSnap.exists()) {
          groupNamesMap[groupId] =
            groupSnap.data().name || `未設定 (${groupId})`;
        } else {
          groupNamesMap[groupId] = `不明 (${groupId})`;
        }
      } catch (error) {
        console.error(`グループ ${groupId} の取得エラー`, error);
        groupNamesMap[groupId] = `取得エラー (${groupId})`;
      }
    });

    await Promise.all(groupPromises);
    setGroupNames(groupNamesMap);
  };

  // `users` コレクションからユーザーリストを取得
  const fetchUsersWithGroups = async () => {
    setLoadingUsers(true);
    try {
      const usersRef = collection(db, "users");
      const usersSnapshot = await getDocs(usersRef);

      const usersData = {};
      const stats = {};

      usersSnapshot.forEach((doc) => {
        const userData = doc.data();
        const hasValidPayment =
          Array.isArray(userData.payments) &&
          userData.payments.some((payment) => payment.paymentPhase === "2");

        if (
          hasValidPayment &&
          userData.purpose2025FirstHalf === "音源視聴 & グループ活動"
        ) {
          const prefecture = userData.prefecture || "不明";

          // 都道府県の初期化
          if (!stats[prefecture]) {
            stats[prefecture] = {
              total: 0,
              assigned: 0,
              hasParticipatingGroup: 0,
            };
          }
          stats[prefecture].total += 1;

          // groups2025を持つ場合
          if (userData.groups2025) {
            stats[prefecture].assigned += 1;
          }

          // participatingGroupを持つ場合
          if (userData.participatingGroup) {
            stats[prefecture].hasParticipatingGroup += 1;
            const userGroups = Array.isArray(userData.participatingGroup)
              ? userData.participatingGroup
              : [userData.participatingGroup];

            userGroups.forEach((groupId) => {
              if (!usersData[groupId]) usersData[groupId] = [];
              usersData[groupId].push({
                userId: doc.id,
                name: userData.name,
                prefecture: prefecture,
                avatar: userData.avatar || "",
                participatingGroup: userData.participatingGroup || "",
                groups2025: userData.groups2025 || "",
                municipality: userData.municipality || "",
              });
            });
          } else {
            // participatingGroupを持たない場合（新規ユーザー）
            const newUserGroup = "新規ユーザ一覧";
            if (!usersData[newUserGroup]) usersData[newUserGroup] = [];
            usersData[newUserGroup].push({
              userId: doc.id,
              name: userData.name,
              prefecture: prefecture,
              avatar: userData.avatar || "",
              participatingGroup: "",
              groups2025: userData.groups2025 || "",
              municipality: userData.municipality || "",
            });
          }
        }
      });

      setUserList(usersData);
      setPrefectureStats(stats); // 🔥 都道府県統計をセット

      const uniqueGroupIds = Object.keys(usersData).filter(
        (id) => id !== "新規ユーザ一覧"
      );
      await fetchGroupNames(uniqueGroupIds);
    } catch (error) {
      console.error("データ取得エラー:", error);
      setUserList({});
    }
    setLoadingUsers(false);
  };

  // 📌 Firestore からグループ一覧を取得（作成日順）
  const fetchGroups = async () => {
    try {
      const groupsRef = collection(db, "groups2025half");
      const q = query(groupsRef, orderBy("createdAt", "desc"));
      const snapshot = await getDocs(q);

      const groups = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const data = docSnap.data();
          const members = data.members || []; // membersフィールドを追加取得
          return {
            id: docSnap.id,
            name: data.name,
            createdAt: data.createdAt?.toDate().toLocaleString() || "不明",
            members, // 🔥 追加
          };
        })
      );

      setGroupList(groups);
    } catch (error) {
      console.error("グループ取得エラー:", error);
    }
  };

  // 🔹 ページ読み込み時にグループ一覧を取得
  useEffect(() => {
    fetchUsersWithGroups(); // 🔥 画面表示時にユーザー一覧を取得
    fetchGroups(); // 🔥 グループ一覧も自動取得
  }, []);

  // グループ作成ダイアログを開く
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // グループ作成ダイアログを閉じる
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewGroupName("");
  };

  // Firestoreに新しいグループを作成
  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) return;

    try {
      const docRef = await addDoc(collection(db, "groups2025half"), {
        name: newGroupName,
        createdAt: Timestamp.now(),
      });

      // 🔥 Firestore に `groupId` を追加
      await updateDoc(doc(db, "groups2025half", docRef.id), {
        groupId: docRef.id,
      });

      handleCloseDialog();
      fetchGroups(); // 🔥 追加後、即時一覧に反映
      setSnackbarMessage("グループを作成しました！");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("グループ作成エラー:", error);
      setSnackbarMessage("グループ作成に失敗しました。");
      setSnackbarOpen(true);
    }
  };

  const addUserToGroup = (groupId, user) => {
    setAddedUsers((prev) => {
      const groupUsers = prev[groupId] || [];
      if (!groupUsers.some((u) => u.userId === user.userId)) {
        return {
          ...prev,
          [groupId]: [...groupUsers, user],
        };
      }
      return prev;
    });
  };

  const handleUpdateGroup = async (groupId) => {
    const groupRef = doc(db, "groups2025half", groupId);

    try {
      // 🔥 現在のメンバーを取得
      const groupDoc = await getDoc(groupRef);
      const currentMembers =
        groupDoc.exists() && groupDoc.data().members
          ? groupDoc.data().members
          : [];

      // 追加するメンバーを準備
      const newMembers = addedUsers[groupId].map((user) => ({
        userId: user.userId,
        name: user.name,
        avatar: user.avatar,
        prefecture: user.prefecture,
        participatingGroup: user.participatingGroup,
      }));

      // 🔥 重複を避けつつ、既存のメンバーに新メンバーを追加
      const mergedMembers = [
        ...currentMembers,
        ...newMembers.filter(
          (newMember) =>
            !currentMembers.some(
              (currentMember) => currentMember.userId === newMember.userId
            )
        ),
      ];

      // 🔥 グループの members を更新（mergedMembersで）
      await updateDoc(groupRef, { members: mergedMembers });

      // 🔥 各ユーザーの groups2025フィールドを更新
      await Promise.all(
        newMembers.map((member) =>
          updateDoc(doc(db, "users", member.userId), {
            groups2025: groupId,
          })
        )
      );

      setSnackbarMessage("グループ情報を更新しました！");
      setSnackbarOpen(true);

      // 🔥 再取得・再レンダリング
      await fetchGroups();

      // 🔥 左側のユーザーリストも再取得（追加）
      await fetchUsersWithGroups();

      // 🔥 addedUsersのリセット（オプション）
      setAddedUsers((prev) => ({
        ...prev,
        [groupId]: [],
      }));
    } catch (error) {
      console.error("更新エラー:", error);
      setSnackbarMessage("グループ情報の更新に失敗しました。");
      setSnackbarOpen(true);
    }
  };

  // メンバー削除処理関数
  const handleRemoveMember = async (groupId, userId) => {
    try {
      // ①グループドキュメントのmembersから削除
      const groupRef = doc(db, "groups2025half", groupId);
      const groupDoc = await getDoc(groupRef);
      const groupData = groupDoc.data();

      if (groupData && groupData.members) {
        const memberToRemove = groupData.members.find(
          (member) => member.userId === userId
        );

        await updateDoc(groupRef, {
          members: arrayRemove(memberToRemove),
        });
      }

      // ②usersコレクションのユーザーのgroups2025の値を消去
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        groups2025: "",
      });

      setSnackbarMessage("メンバーを削除しました");
      setSnackbarOpen(true);

      fetchGroups(); // グループを再取得して画面を更新
    } catch (error) {
      console.error("メンバー削除エラー:", error);
      setSnackbarMessage("メンバー削除に失敗しました");
      setSnackbarOpen(true);
    }
  };

  // 編集ダイアログを開く処理
  const handleOpenEditDialog = (groupId, currentName) => {
    setCurrentEditingGroupId(groupId);
    setCurrentGroupName(currentName);
    setEditDialogOpen(true);
  };

  // 編集ダイアログを閉じる処理
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentGroupName("");
    setCurrentEditingGroupId(null);
  };

  const handleUpdateGroupName = async () => {
    if (!currentGroupName.trim()) {
      setSnackbarMessage("グループ名を入力してください。");
      setSnackbarOpen(true);
      return;
    }

    try {
      const groupRef = doc(db, "groups2025half", currentEditingGroupId);
      await updateDoc(groupRef, { name: currentGroupName });

      setSnackbarMessage("グループ名を更新しました！");
      setSnackbarOpen(true);

      handleCloseEditDialog();
      fetchGroups(); // グループ一覧を更新
    } catch (error) {
      console.error("グループ名更新エラー:", error);
      setSnackbarMessage("グループ名更新に失敗しました。");
      setSnackbarOpen(true);
    }
  };

  const moveData = [
    { userId: "aNgtyUeQfXT8DP8Q3fSqNfB1Kro1", type: "転居" },
    { userId: "1hq5ers9wUOVM2OBrqLRTkYOfA42", type: "ランダム" },
    { userId: "Bj0iGGBaquSUzLctHGH4bLMx4Gv2", type: "ランダム" },
    { userId: "8wytAMiN87beTXLgxFSPnS0dQW93", type: "ランダム" },
    { userId: "LVbXUXvJIWRS1ieHm12WM1S4hSm2", type: "ランダム" },
    { userId: "hOy0PQ8fZtVIfrc9dVqHfUcwO1v2", type: "ランダム" },
    { userId: "2dTrNuEiOROavjAUJ7S1b0BWY423", type: "ランダム" },
    { userId: "FtJNUXsWIzO0bmiGS0xNiCufkxE3", type: "転居" },
    { userId: "gX5TKlD8CkQfE2fRIYh71gkXX9I3", type: "ランダム" },
    { userId: "FZro5tjWGccdhgwZLATBUTlhFLZ2", type: "転居" },
    { userId: "E0gaEiXaVYM7WkL1onFiVElwcav2", type: "ランダム" },
    { userId: "nTgKf36c2tWrv46iWNDzsLV1L4X2", type: "ランダム" },
    { userId: "apzoDWOH9eVJME5TH6m4yqb3VbB3", type: "ランダム" },
    { userId: "aerWlGZoaman9UWgnlAQdsvspb93", type: "ランダム" },
    { userId: "fqQHhj1mQvT3fYFDeeKGm7qDdhJ3", type: "転居" },
    {
      userId: "1tgOjlXRTKeOucdyuK4LzrhvhWz1",
      type: "特定",
      targetGroupId: "bKnWO1FRfsbyJIlyaiUI",
    },
    { userId: "Jp8DhPHF1OXEVEHaxljrV7IwjOH3", type: "ランダム" },
    {
      userId: "jctSte85Dkc8qdh7zFhNiYgVwgr2",
      type: "特定",
      targetGroupId: "nbH4quKlAzgh3gsJVocb",
    },
    {
      userId: "JSeshdZ2EMXywkrv4WSmUxPE50I3",
      type: "特定",
      targetGroupId: "Zy9vNpbDx020yEwmlotk",
    },
    {
      userId: "x1WksnmEP3ZeLLpeEKPUwcj4cyn2",
      type: "特定",
      targetGroupId: "fGVzn0Lo9LQjml1x0jrW",
    },
    { userId: "3mVmVvXezXckfdvdwYstgI1FLFN2", type: "ランダム" },
    { userId: "2HvaFgjkE2Mitqo4h2P4vlg9OQ72", type: "転居" },
    { userId: "meE5eDBMwzNdvtRHVoT8bzhwEJi1", type: "転居" },
    { userId: "3twLtRhTnCNjwxZp2pOICAHJXPd2", type: "ランダム" },
    { userId: "HWgONYpLy4ffZukgW8WzefpaO2a2", type: "ランダム" },
    { userId: "p4BgogmwqYO4qW7Nul9vvdd1uvm2", type: "ランダム" },
    { userId: "2k7xugqO9ceJc841E6FiyPWPgul1", type: "ランダム" },
    { userId: "bQ3xzUlpaccvEqdG6BPSI4G2BCo2", type: "転居" },
    { userId: "6xCZFgIOAbVbGPOQ0hdtziURKCD3", type: "ランダム" },
    {
      userId: "SzozDvBecPcKLcWl4qMK70hntmo2",
      type: "特定",
      targetGroupId: "gCoCXcdPBJCjvnfweN4T",
    },
    { userId: "2bkj4MiaaaUa98QSq6deHl7BVQd2", type: "ランダム" },
    { userId: "psHU7Ju0l2Xt76cy31FHfatbGSQ2", type: "ランダム" },
    { userId: "2EWGKwOH9xWAr7ri8sYQ8d06cNk2", type: "転居" },
    { userId: "nlLQbaVe7IcEo0Nr64VMx8dE3EH3", type: "転居" },
    {
      userId: "reSILwSj5UcxlfBIcteFsp4AIqm2",
      type: "特定",
      targetGroupId: "f8yzVaJB8SY2d9TVht6M",
    },
    { userId: "QuVfDDxdbANWbiknWDwyHblKsDB2", type: "転居" },
    { userId: "EnmiPR5F4wZl6YD3ZclcyEyjcxD3", type: "転居" },
    { userId: "Gg2J7m4vmUWqJKHpnYdY830Qznq2", type: "転居" },
    { userId: "sOiNsBfVmrdk2HCbu3f5m9lcBIp1", type: "転居" },
    { userId: "yWK7G2wPYtUZKZGDs4tOfHN0KUW2", type: "ランダム" },
    {
      userId: "LvXKgrJZJ5QAJogzCM2MCExrpEv1",
      type: "特定",
      targetGroupId: "mMxnCNLAGXg1od3BCKyx",
    },
    { userId: "0JS1AwmaejWywYDCOS2AOrOvSIp1", type: "転居" },
    {
      userId: "1ak2bcZ9oRQjvrPjhs89Ne15gUz2",
      type: "特定",
      targetGroupId: "xngC4yMm4fKlz3uL94WG",
    },
    { userId: "fptn99TjUaTcG21oDN1dULdM8d63", type: "ランダム" },
    { userId: "uqxp6Ff3zfePBLuDuymCW1GHoRj2", type: "転居" },
    { userId: "Wc49N7VMGMOiVki6P0Y00PfJ8BC2", type: "転居" },
    { userId: "om5b5hvpdwWlAXPAkSvWQH5axHE2", type: "ランダム" },
    { userId: "1MxOdv0i2gPuWh6RXUgez1OEBFp1", type: "ランダム" },
    { userId: "FYzJyXssZ5SQwxyEWRNa2hbk7gK2", type: "ランダム" },
    {
      userId: "2iU9ddg4lBfRsNIY184azzBlGv82",
      type: "特定",
      targetGroupId: "bKnWO1FRfsbyJIlyaiUI",
    },
    { userId: "VFLk8Q3Mp9T4wGXCUxnFRVgRXTA3", type: "転居" },
    { userId: "jGDU3ODqTHZPLen27oD1frhwUcA3", type: "転居" },
    { userId: "kVuArR2tuPMEYXY9MgXDMLp7XpW2", type: "転居" },
    { userId: "dBzCmAmyzMOI5NKh6VoAB96hhby2", type: "ランダム" },
    { userId: "zZvnXBMNOXZRUwLTwfYemwE3zxH3", type: "ランダム" },
    { userId: "YsFOY0pBrdT22wdgmg4kk6k8qc32", type: "ランダム" },
    { userId: "uz32Q3sXFWSioPxJkkJzaXDboBw2", type: "ランダム" },
    { userId: "HalT3qw03POn4GrDhQc2nqZFLhU2", type: "転居" },
    {
      userId: "UROCUSULu2NHftOJxJptCJlPxn82",
      type: "特定",
      targetGroupId: "DsE6g4VswkNstlE6fcPr",
    },
    { userId: "QS5DzqOaDWOQbG4Eq3XIcAWKODp2", type: "ランダム" },
    { userId: "WGHObTida5Nm6oK6eLS7arojwtX2", type: "転居" },
    { userId: "r9oLNQb3xreuWRMZRTYqe1ISozm1", type: "転居" },
    { userId: "zny8KHOWDdadOsvQ3qb2NZgv1Ql1", type: "ランダム" },
    { userId: "y0VuHnEXr9dLzAtgdA66h7FQKH82", type: "ランダム" },
    { userId: "5mmb2lApK4f6sPMTycxSSACaAlb2", type: "ランダム" },
    { userId: "ycpYuQAk8eM4r2zkl65yTevBeXt2", type: "ランダム" },
    { userId: "nEBOPPuFSCXrwmKxnz227BcLDln1", type: "ランダム" },
    { userId: "ZaEreWHfKVfOBjXWMaTF0aOrNfv1", type: "ランダム" },
    { userId: "ujYdnCJg7lTt3tfN43ekNWCM8gk1", type: "ランダム" },
    { userId: "1cdfpqIJjdOeEPlnPzOVmsOpV0k1", type: "ランダム" },
    { userId: "W5E8aCPu1UhgQT23BSocBpt2ZQ02", type: "ランダム" },
    { userId: "nVS9NEl5JjQrFjqTpr8pQMQu6oB2", type: "転居" },
    { userId: "I3EwourHHJVDyWPhSWn4MQqCNj13", type: "総解散" },
    { userId: "I6bYaEo4s2ZNsTQXB21UdIBhVVo1", type: "総解散" },
  ];

  const regions = [
    {
      region: "北海道・東北",
      prefectures: [
        "北海道",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
      ],
    },
    {
      region: "関東",
      prefectures: [
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
      ],
    },
    {
      region: "中部",
      prefectures: [
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
      ],
    },
    {
      region: "近畿",
      prefectures: [
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
      ],
    },
    {
      region: "中国",
      prefectures: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
    },
    {
      region: "四国",
      prefectures: ["徳島県", "香川県", "愛媛県", "高知県"],
    },
    {
      region: "九州・沖縄",
      prefectures: [
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
      ],
    },
    {
      region: "地域その他",
      prefectures: ["海外（アメリカ）", "海外（その他）", "不明"],
    },
  ];

  const handlePrefectureChange = (event) => {
    setSelectedPrefecture(event.target.value);
  };

  // 表示時にフィルターする
  const filteredUserList = Object.entries(userList)
    .map(([groupId, users]) => {
      const filteredUsers = users.filter(
        (user) => user.prefecture === selectedPrefecture
      );
      return filteredUsers.length > 0 ? [groupId, filteredUsers] : null;
    })
    .filter(Boolean); // nullを除去

  const handleCopyGroupUIDs = (group) => {
    if (group.members && group.members.length > 0) {
      const uids = group.members.map((member) => member.userId).join("\n");
      navigator.clipboard.writeText(uids).then(() => {
        setSnackbarMessage("UIDをコピーしました！");
        setSnackbarOpen(true);
      });
    }
  };


  const handleBulkCreateGroups = async () => {
    const start = parseInt(bulkStart, 10);
    const end = parseInt(bulkEnd, 10);
  
    if (!bulkPrefecture.trim() || isNaN(start) || isNaN(end) || start > end) {
      setSnackbarMessage("正しい情報を入力してください");
      setSnackbarOpen(true);
      return;
    }
  
    const groupPromises = [];
    for (let i = start; i <= end; i++) {
      const groupName = `2025h${bulkPrefecture}${i}`;
      const newGroup = {
        name: groupName,
        createdAt: Timestamp.now(),
      };
      const ref = await addDoc(collection(db, "groups2025half"), newGroup);
      await updateDoc(doc(db, "groups2025half", ref.id), {
        groupId: ref.id,
      });
    }
  
    setBulkDialogOpen(false);
    setSnackbarMessage("グループを一括作成しました！");
    setSnackbarOpen(true);
    fetchGroups(); // グループ再取得
  };
  

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {/* 📌 ユーザー一覧（左側） */}
        <Grid item xs={12} md={6}>
          {/* 左のユーザ一覧の上に設置 */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>都道府県を選択</InputLabel>
            <Select
              value={selectedPrefecture}
              label="都道府県を選択"
              onChange={handlePrefectureChange}
            >
              {regions.map(({ region, prefectures }) => [
                <ListSubheader key={region}>{region}</ListSubheader>,
                prefectures.map((prefecture) => {
                  const stats = prefectureStats[prefecture] || {
                    total: 0,
                    assigned: 0,
                    hasParticipatingGroup: 0,
                  };
                  return (
                    <MenuItem key={prefecture} value={prefecture}>
                      {`${prefecture} ${stats.assigned} / ${stats.total} (参加中: ${stats.hasParticipatingGroup})`}
                    </MenuItem>
                  );
                }),
              ])}
            </Select>
          </FormControl>

          <Paper
            sx={{
              // mt: 3,
              // padding: 2,
              width: "100%",
              minHeight: 1100,
              maxHeight: 1100,
              overflow: "auto",
            }}
          >
            <Typography variant="h6">ユーザー一覧</Typography>
            {loadingUsers ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: 100 }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <List dense>
                {filteredUserList
                  .sort(([groupIdA], [groupIdB]) => {
                    const nameA = groupNames[groupIdA] || `不明 (${groupIdA})`;
                    const nameB = groupNames[groupIdB] || `不明 (${groupIdB})`;
                    return nameA.localeCompare(nameB);
                  })
                  .map(([groupId, users]) => (
                    <Paper
                      key={groupId}
                      sx={{
                        backgroundColor: getGroupColor(groupId),
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {groupNames[groupId] || `不明 (${groupId})`} ({groupId})
                      </Typography>

                      <List dense>
                        {users.map((user, idx) => {
                          const isNominated =
                            addedUsers[selectedGroupId]?.some(
                              (u) => u.userId === user.userId
                            ) || false; // 🔹 このユーザーがノミネートされているかチェック

                          return (
                            <ListItem
                              dense
                              key={user.userId}
                              sx={{
                                borderRadius: 2,
                                cursor: "pointer",
                                backgroundColor: user.groups2025
                                  ? "#cccccc"
                                  : "inherit",
                              }}
                              onClick={() => {
                                if (user.groups2025) {
                                  setSnackbarMessage(
                                    "このユーザーは既にグループに所属しています。"
                                  );
                                  setSnackbarOpen(true);
                                  return;
                                }

                                if (selectedGroupId) {
                                  addUserToGroup(selectedGroupId, user);
                                } else {
                                  setSnackbarMessage(
                                    "グループを選択してください！"
                                  );
                                  setSnackbarOpen(true);
                                }
                              }}
                            >
                              {/* 🔹 ノミネートされているユーザーにはチェックマークを表示 */}
                              {isNominated && (
                                <CheckCircleIcon
                                  color="success"
                                  sx={{ mr: 1 }}
                                />
                              )}

                              <ListItemAvatar>
                                <Avatar src={user.avatar}>
                                  {user.name.charAt(0)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <>
                                    {`${idx + 1}. ${user.name} (${
                                      user.prefecture
                                    }${
                                      user.prefecture === "北海道" &&
                                      user.municipality
                                        ? ` ${user.municipality}`
                                        : ""
                                    })`}
                                    {moveData.find(
                                      (item) => item.userId === user.userId
                                    ) && (
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: "8px",
                                        }}
                                      >
                                        【
                                        {
                                          moveData.find(
                                            (item) =>
                                              item.userId === user.userId
                                          ).type
                                        }
                                        {moveData.find(
                                          (item) => item.userId === user.userId
                                        ).type === "特定" &&
                                          ` → ${
                                            moveData.find(
                                              (item) =>
                                                item.userId === user.userId
                                            ).targetGroupId
                                          }`}
                                        】
                                      </span>
                                    )}
                                  </>
                                }
                                secondary={
                                  <>
                                    {`userID: ${user.userId}`}
                                    {user.participatingGroup && (
                                      <>
                                        <br />
                                        {`2024Group: ${user.participatingGroup}`}
                                      </>
                                    )}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Paper>
                  ))}
              </List>
            )}
          </Paper>
        </Grid>

        {/* 📌 グループ一覧（右側） */}
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenDialog}
          >
            グループを作成
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setBulkDialogOpen(true)}
          >
            グループ一括作成
          </Button>

          <Paper
            sx={{
              mt: 3,
              padding: 2,
              width: "100%",
              maxHeight: 1100,
              overflow: "auto",
            }}
          >
            <Typography variant="h6">グループ一覧</Typography>
            <List>
              {groupList.map((group) => (
                <Accordion key={group.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${group.id}-content`}
                    id={`panel-${group.id}-header`}
                    sx={{
                      backgroundColor:
                        selectedGroupId === group.id ? "lightblue" : "#f5f5f5",
                      border:
                        selectedGroupId === group.id
                          ? "2px solid blue"
                          : "none",
                      borderRadius: 2,
                      mb: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => handleGroupClick(group.id)}
                  >
                    <ListItem
                      key={group.id}
                      sx={{
                        backgroundColor:
                          selectedGroupId === group.id
                            ? "lightblue"
                            : "#f5f5f5",
                        borderRadius: 2,
                        mb: 1,
                        cursor: "pointer",
                        display: "block",
                      }}
                      onClick={() => handleGroupClick(group.id)}
                    >
                      {/* 🔥 メンバー数を表示（8・9のときに色を強調） */}
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "bold",
                            mr: 1,
                            color:
                              group.members.length === 8 ||
                              group.members.length === 9
                                ? "red"
                                : "black",
                          }}
                        >
                          {group.members.length}
                        </Typography>

                        {/* 🔥 グループ名を表示 */}
                        <Typography
                          sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditDialog(group.id, group.name);
                          }}
                        >
                          {group.name} ({group.id})
                        </Typography>
                      </Box>
                    </ListItem>

                    {/* 🔥 フォーカス時にのみ更新ボタンを表示 */}
                    {selectedGroupId === group.id &&
                      addedUsers[group.id]?.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ ml: 2 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateGroup(group.id);
                          }}
                        >
                          更新
                        </Button>
                      )}
                  </AccordionSummary>

                  <AccordionDetails>
                    {/* 🔥 既存メンバーの表示 */}
                    {group.members.length > 0 && (
                      <List
                        dense
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: 1,
                          padding: 1,
                        }}
                      >
                        {/* 🔥 タイトルとコピーアイコンを横並びに */}
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="subtitle2">
                            現在のメンバー
                          </Typography>

                          {/* 🔥 UIDコピーアイコンを右端に配置 */}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // アコーディオンの開閉を防ぐ
                              handleCopyGroupUIDs(group);
                            }}
                            sx={{ color: "gray" }} // 色を統一
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </Box>{" "}
                        {group.members.map((member, index) => (
                          <ListItem
                            key={member.userId || index}
                            secondaryAction={
                              <DeleteIcon
                                sx={{ cursor: "pointer", color: "red" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveMember(group.id, member.userId);
                                }}
                              />
                            }
                          >
                            <ListItemAvatar>
                              <Avatar src={member.avatar}>
                                {member.name.charAt(0)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${index + 1}. ${member.name} (${
                                member.prefecture
                              })`}
                              secondary={`userID: ${member.userId}\n2024Group: ${member.participatingGroup}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}

                    {/* 🔥 追加予定のメンバー表示 */}
                    {addedUsers[group.id]?.length > 0 && (
                      <List dense>
                        <Typography variant="subtitle2">
                          追加メンバー
                        </Typography>
                        {addedUsers[group.id].map((user, idx) => (
                          <ListItem
                            key={user.userId}
                            sx={{
                              cursor: "pointer",
                              "&:hover": { backgroundColor: "#ffcccc" },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAddedUsers((prev) => ({
                                ...prev,
                                [group.id]: prev[group.id].filter(
                                  (u) => u.userId !== user.userId
                                ),
                              }));
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar src={user.avatar}>
                                {user.name.charAt(0)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${idx + 1}. ${user.name} (${
                                user.prefecture
                              })`}
                              secondary={`参加グループ: ${user.participatingGroup}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
            ;
          </Paper>
        </Grid>
      </Grid>
      {/* グループ作成ダイアログ */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>新しいグループを作成</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="グループ名"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>キャンセル</Button>
          <Button onClick={handleCreateGroup} color="primary">
            作成
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={bulkDialogOpen} onClose={() => setBulkDialogOpen(false)}>
  <DialogTitle>グループ一括作成</DialogTitle>
  <DialogContent>
    <TextField
      label="都道府県"
      fullWidth
      value={bulkPrefecture}
      onChange={(e) => setBulkPrefecture(e.target.value)}
      sx={{ mb: 2 }}
    />
    <TextField
      label="開始番号"
      type="number"
      fullWidth
      value={bulkStart}
      onChange={(e) => setBulkStart(e.target.value)}
      sx={{ mb: 2 }}
    />
    <TextField
      label="終了番号"
      type="number"
      fullWidth
      value={bulkEnd}
      onChange={(e) => setBulkEnd(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setBulkDialogOpen(false)}>キャンセル</Button>
    <Button onClick={handleBulkCreateGroups} variant="contained" color="primary">
      作成
    </Button>
  </DialogActions>
</Dialog>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />

      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>グループ名を編集</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="グループ名"
            value={currentGroupName}
            onChange={(e) => setCurrentGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>キャンセル</Button>
          <Button onClick={handleUpdateGroupName} color="primary">
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FilterGroupActivityUsers;
