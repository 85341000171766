import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  getDoc,
  doc,
  collection,
  query,
  limit,
  onSnapshot,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

const GroupMembers = ({ group }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingDDP, setEditingDDP] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newDDPText, setNewDDPText] = useState("");
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const fetchMembersData = async () => {
    try {
      const membersDataPromises = group.members.map(async (member) => {
        const userId = member.uid || member.userId;
        if (!userId) return null;

        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();

          const ddpRef = collection(db, `users/${userId}/ddps`);
          const q = query(ddpRef, limit(1));
          const querySnapshot = await new Promise((resolve, reject) => {
            onSnapshot(
              q,
              (snapshot) => {
                if (!snapshot.empty) {
                  resolve(snapshot);
                } else {
                  resolve(null);
                }
              },
              reject
            );
          });

          const ddpData = querySnapshot ? querySnapshot.docs[0].data() : null;
          return { ...userData, ddp: ddpData, uid: userId };
        } else {
          return null;
        }
      });

      const membersData = await Promise.all(membersDataPromises);
      setMembers(membersData.filter((member) => member !== null));
    } catch (err) {
      console.error("Error fetching members data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (group && group.members) {
      fetchMembersData();
    } else {
      setError("Group or members data is not provided");
      setLoading(false);
    }
  }, [group]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box>
      <Typography variant="h5">{group.name}</Typography>
      <Typography variant="body1">{group.description}</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {members.map((member, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Avatar
                src={member.avatar}
                alt={member.name}
                sx={{ marginRight: 2 }}
              />
              <Typography>{member.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {member.ddp && (
                <>
                  <Typography sx={{ whiteSpace: "pre-wrap" }}>
                    DDP: {member.ddp.ddp}
                  </Typography>
                  {(member.uid || member.userId) === auth.currentUser?.uid && (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setEditingDDP(member);
                        setNewDDPText(member.ddp.ddp || "");
                        setOpenDialog(true);
                      }}
                    >
                      編集
                    </Button>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          DDPを編集
          <IconButton
            aria-label="info"
            onClick={() => setOpenInfoDialog(true)}
            sx={{ position: "absolute", right: 16, top: 16 }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="DDP"
            type="text"
            fullWidth
            multiline
            minRows={4}
            maxRows={7}
            value={newDDPText}
            onChange={(e) => setNewDDPText(e.target.value)}
            helperText={`${newDDPText.length} 文字`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>キャンセル</Button>
          <Button
            onClick={async () => {
              if (editingDDP) {
                const userId = editingDDP.uid || editingDDP.userId;
                const ddpRef = collection(db, `users/${userId}/ddps`);
                const snapshot = await getDocs(query(ddpRef, limit(1)));

                if (!snapshot.empty) {
                  const docId = snapshot.docs[0].id;
                  await updateDoc(doc(db, `users/${userId}/ddps/${docId}`), {
                    ddp: newDDPText,
                  });
                }

                setOpenDialog(false);
                fetchMembersData();
              }
            }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>DDP設定のポイント</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" fontWeight="bold" mt={2}>
            🔷 DDPの目的と基本概念
          </Typography>
          <Typography paragraph>
            DDPとは：自分が「どうなりたいか（なりたい姿）」を明確にするための考え方。手段や通過点ではなく、願望のコアを言語化する。
            <br /><br />"したい"ではなく"なりたい"を考える：例）「宝くじを当てたい」は“手段”、「経済的に安心した暮らしをしたい」が“なりたい”。
            <br /><br />最終目的は“ありたい”だが、DDPでは“なりたい”にフォーカスする。
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold">
            🔷 DDPを考える上での3つの視点
          </Typography>
          <Typography paragraph>
            Definition（定義）：願いを明確に、具体的にすること。例：「歌手になりたい」ではなく「私が書いた曲で多くの人を感動させたい」。
            <br />Direction（方向）：その願いに向けて今後どのように進むかの指針を持つこと。
            <br />Purpose（目的）：その願いの本質的な理由。「それをすることで、どう感じたいのか」。
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold">
            🔷 DDPを作るときの重要なポイント
          </Typography>
          <Typography paragraph>
            自己中心的であること：「他人と比較した金持ち」ではなく「自分が心地よく感じられる暮らし」など、自分基準で考える。
            <br />時間性を持つこと：「老後が安心したい」など未来全体ではなく、「半年後にどうなっていたいか」など、期間を定める。
            <br />手段と目的を混同しないこと：例）「武道館で歌いたい」は他人依存の手段、「自分が納得できる曲を作りたい」が本来の目的。
            <br />積み上げ方式で考える：現実的なステップを重ねて最終ゴールに近づくようにする。
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold">
            🔷 願いを叶える鍵は「信じきれるか」
          </Typography>
          <Typography paragraph>
            願いは「信じきれたら」叶うという前提がある。
            <br />自分が“思える”ことは、すでにその世界が存在している証拠（量子力学的観点）。
            <br />DDPは、「ただの夢」ではなく、「自分が信じられる願い」としての“なりたい姿”を言語化することで、より現実的にその方向へと動いていくためのツールです。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInfoDialog(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GroupMembers;
