import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Typography,
} from "@mui/material";

const steps = [
  {
    label: "Phase1",
    description: "仲間を知る 4/4-4/14",
    content:
      "●Chatで会話してください。\n●オンライン(zoomなど)で繋がり自己紹介などお互いのことを知ってください。",
  },
  {
    label: "Phase2",
    description: "DDPについて深める 4/15-5/10",
    content:"Comming soon・・・",
  },

];

const Schedule2025H = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: "16px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "600px" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>
                {step.label} - {step.description}
              </StepLabel>
              <StepContent>
                {step.content.split("\n").map((line, idx) => (
                  <Typography key={idx}>{line}</Typography>
                ))}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === steps.length && (
            <Paper square elevation={0} style={{ padding: '16px' }}>
              <Typography>All steps completed - you&apos;re finished</Typography>
            </Paper>
          )} */}
      </div>
    </div>
  );
};

export default Schedule2025H;
