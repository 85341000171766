import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Schedule from "./Schedule";
import Schedule2025H from "./Schedule2025H";

const SophieMission2025H = ({ onClose }) => {
  return (
    <div style={{ width: 300, padding: 16 }}>
      <IconButton onClick={onClose} style={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
      <h2>グループ活動のガイドライン</h2>
      <Schedule2025H />
    </div>
  );
};

export default SophieMission2025H;
