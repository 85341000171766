import React, { useState, useEffect } from "react";
import { db, auth } from "../api/firebase";
import { collection, getDocs, addDoc, doc, getDoc } from "firebase/firestore";
import { TextField, Button, Typography, CircularProgress } from "@mui/material";

const YaeNoInoriForm = () => {
  const [text, setText] = useState("");
  const [existingRecords, setExistingRecords] = useState(new Set());
  const [statusMessage, setStatusMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(null); // null: 読み込み中, true: 管理者, false: 権限なし

  useEffect(() => {
    // 現在のユーザーの isAdmin を取得
    const fetchUserData = async () => {
      if (!auth.currentUser) {
        setIsAdmin(false);
        return;
      }

      const userRef = doc(db, "users", auth.currentUser.uid);
      try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().isAdmin === true) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("ユーザー情報の取得に失敗しました:", error);
        setIsAdmin(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // 既存のデータを取得
    const fetchExistingRecords = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "yaeNoInori"));
        const recordSet = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          recordSet.add(`${data.step}-${data.stepContent}-${data.openDate}`);
        });
        setExistingRecords(recordSet);
      } catch (error) {
        console.error("既存データの取得に失敗しました:", error);
      }
    };

    fetchExistingRecords();
  }, []);

  const handleRegister = async () => {
    if (!auth.currentUser) {
      setStatusMessage("ログインしてください");
      return;
    }

    try {
      const lines = text.trim().split("\n").filter((line) => line);
      const newRecords = [];

      lines.forEach((line) => {
        const parts = line.split("\t");
        if (parts.length !== 7) return;

        const [no, step, stepContent, openDatePattern, openDate, challenge, gain] = parts;
        const recordKey = `${step}-${stepContent}-${openDate}`;

        if (!existingRecords.has(recordKey)) {
          newRecords.push({
            step: parseInt(step, 10),
            stepContent,
            openDatePattern,
            openDate,
            challenge,
            gain,
            createdAt: new Date(),
            createdBy: auth.currentUser.uid,
          });
        }
      });

      if (newRecords.length === 0) {
        setStatusMessage("新規登録すべきデータはありません");
        return;
      }

      // Firestore に追加
      const batchAddPromises = newRecords.map((record) => addDoc(collection(db, "yaeNoInori"), record));
      await Promise.all(batchAddPromises);

      setStatusMessage(`${newRecords.length} 件のデータを登録しました`);
      setExistingRecords(new Set([...existingRecords, ...newRecords.map(r => `${r.step}-${r.stepContent}-${r.openDate}`)]));
      setText(""); // テキストエリアをクリア
    } catch (error) {
      console.error("データ登録エラー:", error);
      setStatusMessage("データ登録に失敗しました");
    }
  };

  if (isAdmin === null) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
        <Typography variant="body1">認証情報を確認中...</Typography>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h6" color="error">アクセス権がありません</Typography>
        <Typography variant="body1">管理者のみこのページにアクセスできます</Typography>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 600, margin: "0 auto", textAlign: "center" }}>
      <Typography variant="h5" gutterBottom>
        八重の祈りデータ登録
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={10}
        variant="outlined"
        placeholder="データを貼り付けてください（タブ区切り）"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleRegister}
        style={{ marginTop: "16px" }}
      >
        登録する
      </Button>
      {statusMessage && (
        <Typography variant="body1" style={{ marginTop: "8px", color: "red" }}>
          {statusMessage}
        </Typography>
      )}
    </div>
  );
};

export default YaeNoInoriForm;
