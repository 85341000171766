import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../api/firebase";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import Post from "./Post";

const Feeds = () => {
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [userTerm, setUserTerm] = useState(null);
  const loadMoreRef = useRef(null);
  const [userPayments, setUserPayments] = useState([]); // ユーザーの支払いフェーズ
  const [isSekaimura, setIsSekaimura] = useState(false);

  useEffect(() => {
    const fetchUserTerm = async () => {
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          // payments 配列から paymentPhase を取得
          const payments = userSnap.data().payments || [];
          const paymentPhases =
            payments?.map((payment) => payment.paymentPhase) || [];

          if (paymentPhases) {
            setUserPayments(paymentPhases); // paymentPhase を term として設定
          } else {
            console.error("No paymentPhase found in user payments");
          }

          // 🔽 isSekaimura の取得を追加
          const isSekaimura = userSnap.data().isSekaimura || false;
          setIsSekaimura(isSekaimura);
        } else {
          console.error("User document does not exist");
        }
      } catch (error) {
        console.error("Error fetching user term:", error);
      }
    };

    fetchUserTerm();
  }, []);

  useEffect(() => {
    if (userPayments === null) return; // Wait for userTerm to be fetched

    const q = query(
      collection(db, "posts"),
      where("isVisible", "==", true),
      orderBy("timestamp", "desc"),
      limit(3)
    );

    const unsubscribePosts = onSnapshot(q, (querySnapshot) => {
      const filteredPosts = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))

      // .filter((post) =>
      //   (post.allowedTerm?.map(String) || []).some((term) =>
      //     userPayments.includes(term)
      //   )
      // );


        .filter((post) =>
          (post.allowedTerm?.map(String) || []).some(
            (term) =>
              userPayments.includes(term) ||
              (isSekaimura && term === "sekaiMura") // 🔹 isSekaimura が true の場合 sekaiMura を許可
          )
        );


      setPosts(filteredPosts);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      if (querySnapshot.docs.length < 3) {
        setHasMore(false);
      }
    });

    return () => {
      unsubscribePosts();
    };
  }, [userPayments]);

  const loadMorePosts = async () => {
    if (lastVisible && !loading && hasMore) {
      setLoading(true);
      const q = query(
        collection(db, "posts"),
        where("isVisible", "==", true),
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(3)
      );

      const querySnapshot = await getDocs(q);
      const newPosts = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((post) =>
          (post.allowedTerm?.map(String) || []).some((term) =>
            userPayments.includes(term)
          )
        );
      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      if (querySnapshot.docs.length < 3) {
        setHasMore(false);
      }

      setLoading(false);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore) {
          loadMorePosts();
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.9 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [lastVisible, loading, hasMore]);

  // if (posts.length === 0) return <div>表示できるものがありません。</div>;
  return (
    <div>
      <div className="mb-5"></div>
      {posts.map((post) => (
        <Post key={post.id} post={post} currentUser={{ term: userTerm }} />
      ))}
      {loading && (
        <div className="text-center my-4">
          <p>読み込み中...</p>
        </div>
      )}
      {posts.length === 0 && !hasMore && (
        <div className="text-center my-4">
          <p>表示するフィードがありません。</p>
        </div>
      )}
      {hasMore && !loading && (
        <div ref={loadMoreRef} className="text-center my-4">
          <button onClick={loadMorePosts} disabled={loading}>
            もっと読み込む
          </button>
        </div>
      )}
    </div>
  );
};

export default Feeds;
