import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Chip,
  Avatar,
  Button,
  Link,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";

const ResolvedRequestsList = ({ users, handleAccordionChange }) => {
  const [resolvedRequests, setResolvedRequests] = useState([]); // 取得したリクエスト
  const [lastDoc, setLastDoc] = useState(null); // 最後に取得したドキュメント
  const [loading, setLoading] = useState(false); // ロード状態
  const observer = useRef(); // Intersection Observer
  const [snackbarOpen, setSnackbarOpen] = useState(false); // スナックバーの表示状態

  // 🔹 Firestore から最初の30件を取得
  useEffect(() => {
    const fetchInitialRequests = async () => {
      setLoading(true);
      const q = query(
        collection(db, "support"),
        orderBy("createdAt", "desc"),
        where("isResolved", "==", true),
        limit(30)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        setResolvedRequests(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]); // 最後のドキュメントを保存
      }
      setLoading(false);
    };

    fetchInitialRequests();
  }, []);

  // 🔹 スクロールで次の30件を取得
  const fetchMoreRequests = useCallback(async () => {
    if (!lastDoc || loading) return; // 既にロード中 or データなしならスキップ
    setLoading(true);

    const q = query(
      collection(db, "support"),
      orderBy("createdAt", "desc"),
      startAfter(lastDoc),
      limit(30)
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      setResolvedRequests((prev) => [
        ...prev,
        ...snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ]);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    }
    setLoading(false);
  }, [lastDoc, loading]);

  // 🔹 Intersection Observer を設定
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect(); // 既存の Observer を解除

      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            fetchMoreRequests(); // 画面に 80% 表示されたら次のデータ取得
          }
        },
        { threshold: 0.8 }
      );

      if (node) observer.current.observe(node);
    },
    [fetchMoreRequests, loading]
  );

  // 🔹 `未解決にする` ボタンが押された時の処理
  const handleUnresolve = async (id) => {
    try {
      const requestDoc = doc(db, "support", id);
      await updateDoc(requestDoc, { isResolved: false });

      // 🔥 即時リスト更新（Firestoreの更新を待たずに削除）
      setResolvedRequests((prevRequests) =>
        prevRequests.filter((req) => req.id !== id)
      );

      // 🔥 成功通知
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Firestore更新エラー:", error);
    }
  };

  return (
    <List>
      {resolvedRequests.map((request, index) => (
        <Accordion
          key={request.id}
          sx={{
            mb: 2,
            backgroundColor:
              request.responses && request.responses.length > 0
                ? "#e8f5e9"
                : "white",
            transition: "background-color 0.3s",
          }}
          onChange={() => handleAccordionChange(request.userId)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <Typography variant="body2" sx={{ mr: 2, fontWeight: "bold" }}>
                {index + 1}.
              </Typography>
              <Avatar
                alt={request.name}
                src={request.avatar || users[request.userId]?.avatar}
                sx={{ mr: 1 }}
              />
              <Typography variant="body1">
                {request.name || users[request.userId]?.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Typography variant="h6">{request.category}</Typography>
              <Chip label="解決済み" color="primary" sx={{ ml: 2 }} />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {dayjs(request.createdAt.toDate()).fromNow()} (
                {dayjs(request.createdAt.toDate()).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                )
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                handleUnresolve(request.id);
                // toggleResolvedStatus(request.id, request.isResolved);
              }}
              sx={{ alignSelf: "flex-start", ml: "auto" }}
            >
              未解決にする
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <Typography
              variant="body2"
              color="textPrimary"
              sx={{
                mt: 1,
                p: 1,
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              <strong>メッセージ:</strong> {request.message || "該当なし"}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              <strong>メール:</strong>{" "}
              {request.email ? (
                <Link href={`mailto:${request.email}`}>{request.email}</Link>
              ) : (
                "該当なし"
              )}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              <strong>ログインメール:</strong>{" "}
              {request.loginEmail ? (
                <Link href={`mailto:${request.loginEmail}`}>
                  {request.loginEmail}
                </Link>
              ) : (
                "該当なし"
              )}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              <strong>返信希望メール:</strong>{" "}
              {request.replyEmail ? (
                <Link href={`mailto:${request.replyEmail}`}>
                  {request.replyEmail}
                </Link>
              ) : (
                "該当なし"
              )}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              <strong>参加中グループ:</strong>{" "}
              {request.groupId ? (
                <Link
                  href={`/group/${request.groupId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                >
                  {request.groupId}
                </Link>
              ) : (
                "該当なし"
              )}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              <strong>ユーザID:</strong> {request.userId || "該当なし"}
            </Typography>

            {/* 🔹 対応履歴を表示 */}
            {request.responses && request.responses.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">対応履歴</Typography>
                {request.responses.map((response, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" color="textSecondary">
                      {dayjs(response.respondedAt.toDate()).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                      {" - "}
                      {users[response.responderId]?.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {response.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="textSecondary">
                対応履歴はありません。
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <div ref={lastElementRef} />

      {/* 🔹 解決通知の Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="未解決にしました"
      />
    </List>
  );
};

export default ResolvedRequestsList;
