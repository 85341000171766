import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebase";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ReiNoInoriCount = () => {
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const [groupInfo, setGroupInfo] = useState(null);
  const [groupLoading, setGroupLoading] = useState(false);

  const [progress, setProgress] = useState({
    total: 0,
    current: 0,
    estimatedTime: 0,
  });

  useEffect(() => {
    fetchAllData();
  }, []);

  // すでに定義済みのstateに追加
  const [selectedPrefecture, setSelectedPrefecture] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [leftLoading, setLeftLoading] = useState(false);

  const [filteredGroups, setFilteredGroups] = useState([]);

  const REGION_PREF_ORDER = {
    北海道: ["北海道"],
    東北: ["青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県"],
    関東: [
      "東京都",
      "神奈川県",
      "埼玉県",
      "千葉県",
      "茨城県",
      "栃木県",
      "群馬県",
    ],
    "中部・北陸": [
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
    ],
    近畿: [
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
    ],
    中国: ["鳥取県", "島根県", "岡山県", "広島県", "山口県"],
    四国: ["徳島県", "香川県", "愛媛県", "高知県"],
    "九州・沖縄": [
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ],
    海外: ["海外（アメリカ）", "海外（その他）"],
  };

  const normalizePrefecture = (pref) => {
    if (!pref) return "";
    const trimmed = pref.trim().replace(/ /g, "");
    if (trimmed === "海外(アメリカ)" || trimmed === "海外（アメリカ）")
      return "海外（アメリカ）";
    if (trimmed === "その他海外" || trimmed === "海外（その他）")
      return "海外（その他）";
    return trimmed;
  };

  const fetchAllData = async () => {
    setLoading(true);
    setProgress({ total: 0, current: 0, estimatedTime: 0 });

    // ReiNoInoriのデータ取得
    const reiNoInoriRef = collection(db, "ReiNoInori");
    const reiSnapshot = await getDocs(reiNoInoriRef);
    const reiTotal = reiSnapshot.size;

    // groups2025halfのデータ取得
    const groupsRef = collection(db, "groups2025half");
    const groupsSnapshot = await getDocs(groupsRef);
    const groupsTotal = groupsSnapshot.size;

    const totalDocs = reiTotal + groupsTotal;
    let currentCount = 0;
    setProgress({
      total: totalDocs,
      current: currentCount,
      estimatedTime: Math.ceil(totalDocs * 0.01),
    });

    // ReiNoInoriの処理
    const countsByPrefecture = {};

    reiSnapshot.forEach((doc) => {
      const { prefecture, wantAdvice } = doc.data();
      const normalizedPref = normalizePrefecture(prefecture); // ★ 追加！

      if (normalizedPref) {
        if (!countsByPrefecture[normalizedPref]) {
          countsByPrefecture[normalizedPref] = { total: 0, advice: 0 };
        }
        countsByPrefecture[normalizedPref].total += 1;
        if (wantAdvice) countsByPrefecture[normalizedPref].advice += 1;
      }

      currentCount += 1;
      setProgress({
        total: totalDocs,
        current: currentCount,
        estimatedTime: Math.ceil((totalDocs - currentCount) * 0.01),
      });
    });

    setCount(countsByPrefecture);

    // グループの処理（地域別表示と合同グループ処理）
    const groupData = groupsSnapshot.docs.map((doc) => {
      const data = doc.data();
      const members = data.members || [];
      const prefCounts = {};

      members.forEach((member) => {
        const rawPref = member.prefecture || "未設定";
        const pref = normalizePrefecture(rawPref); // ← ここで統一！！
        prefCounts[pref] = (prefCounts[pref] || 0) + 1;
      });
      currentCount += 1;
      setProgress({
        total: totalDocs,
        current: currentCount,
        estimatedTime: Math.ceil((totalDocs - currentCount) * 0.01),
      });

      return {
        id: doc.id,
        name: data.name || doc.id,
        prefectures: prefCounts,
        members,
      };
    });

    const individualGroups = [];
    const combinedGroups = [];

    groupData.forEach((group) => {
      const nameWithoutPrefix = group.name.replace(/^2025h/, "");
      const isCombined = nameWithoutPrefix.includes("・");
      if (isCombined) {
        combinedGroups.push(group);
      } else {
        individualGroups.push(group);
      }
    });

    // groupData.forEach((group) => {
    //   group.members?.forEach((member) => {
    //     if (member.prefecture?.includes("海外")) {
    //       console.log("🌍 海外メンバー発見！", group.name, member.prefecture);
    //     }
    //   });
    // });

    // マッチング用の都道府県名の簡易化関数
    const simplifyPrefName = (pref) => pref.replace(/(都|道|府|県)$/, "");

    const sortedGroups = [];

    // 簡易化した都道府県名でマッチングするよう修正
    Object.entries(REGION_PREF_ORDER).forEach(([region, prefs]) => {
      prefs.forEach((pref) => {
        const simplePref = simplifyPrefName(pref);
        individualGroups
          .filter(
            (group) => Object.keys(group.prefectures).includes(pref) // ← 🔁 これに修正！
          )
          .forEach((group) =>
            sortedGroups.push({ ...group, region, prefecture: pref })
          );
      });
    });

    // 合同グループ追加
    combinedGroups.forEach((group) =>
      sortedGroups.push({
        ...group,
        region: "合同グループ",
        prefecture: "複数都道府県",
      })
    );

    // ★★★ ここに追加！
    // console.log("🧪 グループごとのprefecturesキー一覧:");
    // sortedGroups.forEach((group) => {
    //   console.log(
    //     `🔹 ${group.name}（ID: ${group.id}）`,
    //     Object.keys(group.prefectures)
    //   );
    // });

    // 🔽 ここに追加！
    // console.log("🧪 グループごとの prefectures 内容確認 ↓↓↓↓↓↓↓");
    // sortedGroups.forEach((group) => {
    //   console.log(
    //     "📦 グループ:",
    //     group.name,
    //     "⇒ prefectures:",
    //     group.prefectures
    //   );
    // });

    setGroupInfo(sortedGroups);
    setLoading(false);
  };

  // 都道府県選択時の処理
  useEffect(() => {
    if (selectedPrefecture) {
      (async () => {
        const collRef = collection(db, "ReiNoInori");
        const snapshot = await getDocs(collRef);
        const data = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (item) =>
              normalizePrefecture(item.prefecture) ===
                normalizePrefecture(selectedPrefecture) &&
              item.wantAdvice === true
          );

        setFilteredData(data);
        setLeftLoading(false); // ← データ取得完了
      })();
    }
  }, [selectedPrefecture]);

  useEffect(() => {
    if (!selectedPrefecture || !groupInfo) return;

    const normalizedPref = normalizePrefecture(selectedPrefecture); // ★ここで正規化！

    const matchedGroups = groupInfo.filter(
      (group) => group.prefectures[normalizedPref] > 0
    );

    setFilteredGroups(matchedGroups);
  }, [selectedPrefecture, groupInfo]);

  return (
    <Box sx={{ textAlign: "center", p: 2, position: "relative" }}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.6)",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <CircularProgress color="inherit" size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            データを取得中...
          </Typography>
          <Typography variant="body2">少々お待ちください。</Typography>
        </Box>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* // 表示部分（Grid左側）の完全置き換えコード */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: "left",
              height: "80vh",
              overflowY: "auto",
              p: 0,
              border: "1px solid #eee",
              borderRadius: "4px",
              position: "relative",
            }}
          >
            {/* stickyヘッダー：セレクトボックス */}
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                bgcolor: "#fff",
                pb: 1,
                pt: 0,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                ReiNoInoriデータ詳細：
              </Typography>

              <FormControl fullWidth>
                <InputLabel>都道府県を選択</InputLabel>

                <Select
                  labelId="pref-select-label"
                  id="pref-select"
                  value={selectedPrefecture}
                  label="都道府県を選択"
                  onChange={(e) => {
                    // console.log("選択された:", e.target.value);
                    setSelectedPrefecture(e.target.value);
                    setFilteredData([]);
                    setLeftLoading(true);
                  }}
                >
                  {Object.entries(REGION_PREF_ORDER).flatMap(
                    ([region, prefs]) => {
                      const items = [
                        <MenuItem
                          key={`header-${region}`}
                          disabled
                          sx={{ fontWeight: "bold", opacity: 0.7 }}
                        >
                          ― {region} ―
                        </MenuItem>,
                        ...prefs.map((pref) => (
                          <MenuItem key={pref} value={pref}>
                            {pref}（{count?.[pref]?.advice ?? 0}件）
                          </MenuItem>
                        )),
                      ];
                      return items;
                    }
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* ローディングアニメーション（左側専用） */}
            {leftLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!leftLoading &&
              selectedPrefecture &&
              filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <Accordion key={`rei-${item.id}-${index}`} sx={{ mb: 1 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {index + 1}. {item.name || "未設定"} /{" "}
                      {item.mail || "未設定"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      <strong>DDP：</strong>
                      {item.ddp || "未設定"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>都道府県：</strong>
                      {item.prefecture}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}

            {!leftLoading && selectedPrefecture && filteredData.length === 0 && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                該当データがありません。
              </Typography>
            )}

            {!selectedPrefecture && !leftLoading && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                都道府県を選択すると、詳細データが表示されます。
              </Typography>
            )}
          </Box>
        </Grid>

        {/* グループ情報（右） */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: "left",
              height: "80vh",
              overflowY: "auto",
              p: 1,
              border: "1px solid #eee",
              borderRadius: "4px",
            }}
          >
            {filteredGroups && selectedPrefecture && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mb: 1 }}
                >
                  「{selectedPrefecture}」に関わるグループ（
                  {filteredGroups.length}件）：
                </Typography>

                {/* 都道府県内メンバー合計人数を算出 */}
                {(() => {
                  const totalPrefMemberCount = filteredGroups.reduce(
                    (sum, group) =>
                      sum + (group.prefectures[selectedPrefecture] || 0),
                    0
                  );

                  return filteredGroups.map((group, index) => {
                    const memberCount =
                      group.prefectures[selectedPrefecture] || 0;
                    const estimatedCount =
                      totalPrefMemberCount > 0
                        ? Math.round(
                            (filteredData.length * memberCount) /
                              totalPrefMemberCount
                          )
                        : 0;

                    return (
                      <Box
                        key={`group-${group.id}`}
                        sx={{
                          mb: 2,
                          p: 1,
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          {index + 1}. {group.name}（ID: {group.id}）
                        </Typography>

                        {Object.entries(group.prefectures).map(
                          ([pref, count]) => (
                            <Typography
                              key={pref}
                              sx={{
                                ml: 2,
                                fontWeight:
                                  pref === selectedPrefecture
                                    ? "bold"
                                    : "normal",
                                color:
                                  pref === selectedPrefecture
                                    ? "primary.main"
                                    : "text.secondary",
                              }}
                            >
                              {pref}：{count}名
                              {pref === selectedPrefecture && (
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "0.8rem",
                                    color: "success.main",
                                    ml: 2,
                                  }}
                                >
                                  想定割当: {estimatedCount}件
                                </Typography>
                              )}
                            </Typography>
                          )
                        )}
                      </Box>
                    );
                  });
                })()}

                {filteredGroups.length === 0 && (
                  <Typography variant="body2" color="textSecondary">
                    この都道府県に属するグループは見つかりませんでした。
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReiNoInoriCount;
