import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography, Container, Paper, CircularProgress } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const PaymentInfo = () => {
  const [sessionId, setSessionId] = useState("");
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true); // 認証チェック中
  const functions = getFunctions();
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  // 🔥 管理者チェック（isAdminがfalseなら/homeへリダイレクト）
  useEffect(() => {
    const checkAdmin = async () => {
      const user = auth.currentUser;
      if (!user) {
        navigate("/home");
        return;
      }

      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists() || !userSnap.data().isAdmin) {
        navigate("/home");
      } else {
        setCheckingAuth(false);
      }
    };

    checkAdmin();
  }, [auth, db, navigate]);

  // 🔄 認証チェック中はローディング表示
  if (checkingAuth) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>認証中...</Typography>
      </Box>
    );
  }

  // 🔥 支払い情報取得
  const fetchPaymentInfo = async () => {
    if (!sessionId) {
      alert("Session ID を入力してください");
      return;
    }

    setLoading(true);
    setPaymentInfo(null);

    try {
      const getPaymentInfo = httpsCallable(functions, "getPaymentInfo");
      const result = await getPaymentInfo({ sessionId });

      console.log("支払い情報:", result.data);
      setPaymentInfo(result.data);
    } catch (error) {
      console.error("エラー:", error);
      alert("支払い情報の取得に失敗しました");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 3, textAlign: "center", background: "#1e1e1e", color: "#fff" }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", mb: 2 }}>
          💳 Stripe 支払い情報取得
        </Typography>

        {/* MUIのテキストボックス */}
        <TextField
          fullWidth
          label="Session ID"
          variant="outlined"
          value={sessionId}
          onChange={(e) => setSessionId(e.target.value)}
          sx={{
            mb: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#666" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#ff9800" },
              "& input": { color: "#fff" },
              "& label": { color: "#aaa" },
              "& label.Mui-focused": { color: "#ff9800" },
            },
          }}
        />

        {/* 取得ボタン */}
        <Button
          variant="contained"
          fullWidth
          onClick={fetchPaymentInfo}
          sx={{
            background: "#ff9800",
            color: "#fff",
            fontWeight: "bold",
            py: 1.5,
            "&:hover": { background: "#e68900" },
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "支払い情報を取得"}
        </Button>

        {/* 支払い情報の表示 */}
        {paymentInfo && (
          <Box mt={4} sx={{ textAlign: "left" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              支払い詳細
            </Typography>
            <Typography>💰 金額: {paymentInfo.paymentIntent?.amount} {paymentInfo.session?.currency?.toUpperCase()}</Typography>
            <Typography>📅 ステータス: {paymentInfo.paymentIntent?.status}</Typography>
            <Typography>📄 ID: {paymentInfo.paymentIntent?.id}</Typography>
            <Typography>👤 顧客名: {paymentInfo.session?.customer_details?.name || "不明"}</Typography>
            <Typography>📧 メール: {paymentInfo.session?.customer_details?.email || "不明"}</Typography>
            <Typography>🆔 ユーザーID: {paymentInfo.session?.metadata?.userId || "不明"}</Typography>
            <Typography>🔄 支払いフェーズ: {paymentInfo.session?.metadata?.paymentPhase || "不明"}</Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default PaymentInfo;
