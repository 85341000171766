import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, Stack } from '@mui/material';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../api/firebase';

const UidGroupChecker = () => {
  const [uids, setUids] = useState('');
  const [results, setResults] = useState([]);

  const handleCheckGroups = async () => {
    const uidArray = uids.split('\n').map(uid => uid.trim()).filter(uid => uid);
    const promises = uidArray.map(async uid => {
      const userDocRef = doc(collection(db, 'users'), uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        return { uid, group: userDocSnap.data().groups2025 || '未設定' };
      } else {
        return { uid, group: '存在しないユーザー' };
      }
    });

    const groupResults = await Promise.all(promises);
    setResults(groupResults);
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, maxWidth: 600, margin: 'auto' }}>
      <Stack spacing={2}>
        <Button variant="contained" onClick={handleCheckGroups}>
          グループ調査
        </Button>
        <TextField
          multiline
          minRows={6}
          variant="outlined"
          placeholder="ここにUIDを改行区切りで入力してください"
          value={uids}
          onChange={(e) => setUids(e.target.value)}
        />
        {results.map(({ uid, group }, index) => (
          <Typography key={uid}>
            {index + 1}. {uid}: {group}
          </Typography>
        ))}
      </Stack>
    </Paper>
  );
};

export default UidGroupChecker;
