import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import hokkaidoMap from "../json/hokkaido_municipality.json"; // 北海道の市区町村レベルのTopoJSONデータ
import { db, auth } from "../api/firebase";
import { collection, query, where, getDocs, setDoc, doc, orderBy, limit, getDoc } from "firebase/firestore";
import { Typography, CircularProgress, Box, Paper, Button, List, ListItem, ListItemText } from "@mui/material";

// 北海道の市区町村マッピング
const cityMapping = {
  "Sapporo": "札幌市", "Ebetsu": "江別市", "Chitose": "千歳市", "Eniwa": "恵庭市", "Kitahiroshima": "北広島市", "Ishikari": "石狩市", 
  "Tōbetsu": "当別町", "Shinshinotsu": "新篠津村", "Iwamizawa": "岩見沢市", "Yūbari": "夕張市", "Bibai": "美唄市", "Ashibetsu": "芦別市", 
  "Akabira": "赤平市", "Mikasa": "三笠市", "Takikawa": "滝川市", "Sunagawa": "砂川市", "Utashinai": "歌志内市", "Fukagawa": "深川市", 
  "Nanporo": "南幌町", "Naie": "奈井江町", "Kamisunagawa": "上砂川町", "Yuni": "由仁町", "Naganuma": "長沼町", "Kuriyama": "栗山町", 
  "Tsukigata": "月形町", "Urausu": "浦臼町", "Shintotsukawa": "新十津川町", "Moseushi": "妹背牛町", "Chippubetsu": "秩父別町", "Uryū": "雨竜町", 
  "Hokuryū": "北竜町", "Numata": "沼田町", "Otaru": "小樽市", "Kutchan": "倶知安町", "Suttsu": "寿都町", "Kuromatsunai": "黒松内町", 
  "Rankoshi": "蘭越町", "Niseko": "ニセコ町", "Kimobetsu": "喜茂別町", "Kyōgoku": "京極町", "Kyōwa": "共和町", "Iwanai": "岩内町", 
  "Shakotan": "積丹町", "Furubira": "古平町", "Niki": "仁木町", "Yoichi": "余市町", "Shimamaki": "島牧村", "Makkari": "真狩村", 
  "Rusutsu": "留寿都村", "Tomari": "泊村", "Kamoenai": "神恵内村", "Akaigawa": "赤井川村", "Muroran": "室蘭市", "Tomakomai": "苫小牧市", 
  "Noboribetsu": "登別市", "Date": "伊達市", "Toyoura": "豊浦町", "Tōyako": "洞爺湖町", "Sōbetsu": "壮瞥町", "Shiraoi": "白老町", 
  "Atsuma": "厚真町", "Abira": "安平町", "Mukawa": "むかわ町", "Urakawa": "浦河町", "Hidaka": "日高町", "Biratori": "平取町", 
  "Niikappu": "新冠町", "Samani": "様似町", "Erimo": "えりも町", "Shinhidaka": "新ひだか町", "Hakodate": "函館市", "Hokuto": "北斗市", 
  "Matsumae": "松前町", "Fukushima": "福島町", "Shiriuchi": "知内町", "Kikonai": "木古内町", "Nanae": "七飯町", "Shikabe": "鹿部町", 
  "Mori": "森町", "Yakumo": "八雲町", "Oshamanbe": "長万部町", "Esashi": "江差町", "Kaminokuni": "上ノ国町", "Assabu": "厚沢部町", 
  "Otobe": "乙部町", "Okushiri": "奥尻町", "Imakane": "今金町", "Setana": "せたな町", "Asahikawa": "旭川市", "Furano": "富良野市", 
  "ShibetsuCity": "士別市", "Nayoro": "名寄市", "Takasu": "鷹栖町", "Higashikagura": "東神楽町", "Tōma": "当麻町", "Pippu": "比布町", "Kamiyūbetsu":"上湧別町",
  "Aibetsu": "愛別町", "Kamikawa": "上川町", "Higashikawa": "東川町", "Biei": "美瑛町", "Kamifurano": "上富良野町", "Nakafurano": "中富良野町", 
  "Minamifurano": "南富良野町", "Horokanai": "幌加内町", "Wassamu": "和寒町", "Kenbuchi": "剣淵町", "Shimokawa": "下川町", "Bifuka": "美深町", 
  "Nakagawa": "中川町", "Shimukappu": "占冠村", "Otoineppu": "音威子府村", "Rumoi": "留萌市", "Mashike": "増毛町", "Obira": "小平町", 
  "Tomamae": "苫前町", "Haboro": "羽幌町", "Enbetsu": "遠別町", "Teshio": "天塩町", "Shosanbetsu": "初山別村", "Wakkanai": "稚内市", 
  "Hamatonbetsu": "浜頓別町", "Nakatonbetsu": "中頓別町", "EsashiCapital": "枝幸町", "Toyotomi": "豊富町", "Horonobe": "幌延町", "Rebun": "礼文町", 
  "Rishiri": "利尻町", "Rishirifuji": "利尻富士町", "Sarufutsu": "猿払村", "Abashiri": "網走市", "Kitami": "北見市", "Monbetsu": "紋別市", 
  "Bihoro": "美幌町", "Tsubetsu": "津別町", "Ōzora": "大空町", "Shari": "斜里町", "Kiyosato": "清里町", "Koshimizu": "小清水町", 
  "Kunneppu": "訓子府町", "Oketo": "置戸町", "Saroma": "佐呂間町", "Engaru": "遠軽町", "Yūbetsu": "湧別町", "Takinoue": "滝上町", 
  "Okoppe": "興部町", "Ōmu": "雄武町", "Nishiokoppe": "西興部村", "Obihiro": "帯広市", "Otofuke": "音更町", "Shihoro": "士幌町", 
  "Kamishihoro": "上士幌町", "Shikaoi": "鹿追町", "Shintoku": "新得町", "Shimizu": "清水町", "Memuro": "芽室町", "Taiki": "大樹町", 
  "Hiroo": "広尾町", "Makubetsu": "幕別町", "Ikeda": "池田町", "Toyokoro": "豊頃町", "Honbetsu": "本別町", "Ashoro": "足寄町", 
  "Rikubetsu": "陸別町", "Urahoro": "浦幌町", "Nakasatsunai": "中札内村", "Sarabetsu": "更別村", "KushiroCity": "釧路市", "Kushiro": "釧路町", 
  "Akkeshi": "厚岸町", "Hamanaka": "浜中町", "Shibecha": "標茶町", "Teshikaga": "弟子屈町", "Shiranuka": "白糠町", "Tsurui": "鶴居村", 
  "Nemuro": "根室市", "Betsukai": "別海町", "Nakashibetsu": "中標津町", "Shibetsu": "標津町", "Rausu": "羅臼町"
};


const fetchUsersByCity = async () => {
  const userCollection = collection(db, "users");
  const q = query(
    userCollection,
    where("purpose2025FirstHalf", "==", "音源視聴 & グループ活動"),
    where("prefecture", "==", "北海道")
  );
  const userSnapshot = await getDocs(q);

  const userData = userSnapshot.docs
    .map((doc) => doc.data())
    .filter(user => !user.groups2025 || user.groups2025 === "" || user.groups2025 === null);

  const cityCounts = {};
  userData.forEach((user) => {
    const city = user.municipality;
    if (cityCounts[city]) {
      cityCounts[city] += 1;
    } else {
      cityCounts[city] = 1;
    }
  });

  return cityCounts;
};



const getColorForCount = (count) => {
  if (count >= 10) return "#FF0000"; // 赤
  if (count >= 8) return "#FF7F00"; // オレンジ
  if (count >= 4) return "#FFFF00"; // 黄色
  if (count >= 2) return "#7FFFF0"; // ライム
  if (count >= 1) return "#00FF00"; // 緑
  return "#D6D6DA"; // グレー
};

const fetchStatistics = async () => {
  const statisticsCollection = collection(db, "hokkaido");
  const q = query(statisticsCollection, orderBy("date", "desc"), limit(1));
  const statisticsSnapshot = await getDocs(q);

  if (!statisticsSnapshot.empty) {
    return statisticsSnapshot.docs[0].data().data;
  }

  return null;
};

const updateStatistics = async (data) => {
  const today = new Date().toISOString().split("T")[0];
  await setDoc(doc(db, "hokkaido", today), {
    date: today,
    data: data,
  });
};

const fetchUsersInCity = async (city) => {
  const userCollection = collection(db, "users");
  const q = query(
    userCollection,
    where("municipality", "==", city),
    where("purpose2025FirstHalf", "==", "音源視聴 & グループ活動"),
    where("prefecture", "==", "北海道")
  );
  const userSnapshot = await getDocs(q);

  return userSnapshot.docs
    .map((doc) => doc.data())
    .filter(user => !user.groups2025 || user.groups2025 === "" || user.groups2025 === null);
};



const HokkaidoMapComponent = () => {
  const [cityCounts, setCityCounts] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [cityCount, setCityCount] = useState(0);
  const [cityUsers, setCityUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(false); // ここにcityLoadingステートを追加
  const [userCity, setUserCity] = useState("");

  const currentUser = auth.currentUser;

  const loadData = async () => {
    const stats = await fetchStatistics();
    if (stats) {
      setCityCounts(stats);
    } else {
      const counts = await fetchUsersByCity();
      setCityCounts(counts);
      await updateStatistics(counts);
    }
    setLoading(false);
  };

  const loadUserCity = async () => {
    if (currentUser) {
      const userDoc = await getDoc(doc(db, "users", currentUser.uid));
      if (userDoc.exists() && userDoc.data().prefecture === "北海道") {
        setUserCity(userDoc.data().municipality);
      }
    }
  };

  useEffect(() => {
    loadData();
    loadUserCity();
  }, []);

  const handleGeographyClick = async (geo) => {
    const cityInJapanese = cityMapping[geo.properties.NAME_2] || geo.properties.NAME_2;
    setSelectedCity(cityInJapanese);
    setCityCount(cityCounts[cityInJapanese] || 0);
    setCityLoading(true);
    const users = await fetchUsersInCity(cityInJapanese);
    setCityUsers(users);
    setCityLoading(false);
  };

  const handleUpdateStatistics = async () => {
    setLoading(true);
    const counts = await fetchUsersByCity();
    setCityCounts(counts);
    await updateStatistics(counts);
    setLoading(false);
  };

  // console.log("cityCOunt =>",cityCount)
  // console.log("cityUsers =>",cityUsers)
  return (
    <div className="h-fit border rounded-md shadow-lg" style={{ position: "relative" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateStatistics}
        sx={{ position: "absolute", left: "20px", top: "20px", zIndex: 1 }}
      >
        情報取得
      </Button>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{ scale: 3000, center: [141, 43] }} // 北海道の中心に合わせて調整
          >
            <Geographies geography={hokkaidoMap}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const { NAME_2, centroid } = geo.properties;
                  const cityInJapanese = cityMapping[NAME_2];
                  const count = cityCounts[cityInJapanese] || 0;
                  return (
                    <g key={geo.rsmKey}>
                      <Geography
                        geography={geo}
                        onClick={() => handleGeographyClick(geo)}
                        style={{
                          default: {
                            fill: getColorForCount(count),
                            outline: "none",
                            stroke: "#000", // 市区町村境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                          hover: {
                            fill: "#F53",
                            outline: "none",
                            stroke: "#000", // 市区町村境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                          pressed: {
                            fill: "#E42",
                            outline: "none",
                            stroke: "#000", // 市区町村境の線をくっきりと表示
                            strokeWidth: 0.5,
                          },
                        }}
                      />
                      {centroid && (
                        <text
                          x={centroid[0]}
                          y={centroid[1]}
                          textAnchor="middle"
                          style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: "10px" }}
                        >
                          {NAME_2}
                        </text>
                      )}
                    </g>
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          <Box
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              width: "250px",
              maxHeight:"600px",
              overflowY:"auto",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              padding: "10px"
            }}
          >
            <Typography variant="h6">
              {selectedCity}
            </Typography>
            {cityLoading ? ( // cityLoadingステートを使用
              <CircularProgress />
            ) : (
              <>
              <Typography variant="body1">
                {cityCount} 人
              </Typography>
              <List>
                {cityUsers.map((user, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      bgcolor: user.participatingGroup ? "#FFEDED" : "inherit", // participatingGroupがあれば背景色を変更
                    }}
                  >
                    <ListItemText
                      primary={`${index + 1}. ${user.name}`}
                      secondary={user.email}
                    />
                  </ListItem>
                ))}
              </List>
            </>
            
            )}
          </Box>
          <Paper
            sx={{
              position: "absolute",
              right: "20px",
              bottom: "20px",
              width: "200px",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              padding: "10px"
            }}
          >
            <Typography variant="h6">
              凡例
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FF0000", mr: 1 }} />
              <Typography variant="body2">
                > 10 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FF7F00", mr: 1 }} />
              <Typography variant="body2">
                > 8 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#FFFF00", mr: 1 }} />
              <Typography variant="body2">
                > 4 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#7FFF00", mr: 1 }} />
              <Typography variant="body2">
                > 2 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#00FF00", mr: 1 }} />
              <Typography variant="body2">
                > 1 人
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ width: "20px", height: "20px", backgroundColor: "#D6D6DA", mr: 1 }} />
              <Typography variant="body2">
                0 人
              </Typography>
            </Box>
          </Paper>
        </>
      )}
    </div>
  );
};

export default HokkaidoMapComponent;
