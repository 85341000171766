import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // 追加
import { auth, db } from "../api/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useAuth } from "../context/auth";
import { memo } from "react";
import Grid from "@mui/material/Grid";
import ToDoList from "./ToDoList";
import NextMissionList from "./NextMissionList";
import Feed from "./Feed";
import AddPostDialog from "./AddPostDialog";
import RightSections from "./RightSections";
import AudioPlayer from "./AudioPlayer";
import Announcement from "./Announcement";
import PurposeComponent from "./PurposeComponent";
import PurposeSetting from "./PurposeSetting";
import HokkaidoMunicipalitySelect from "./HokkaidoMunicipalitySelect";
import Feeds from "./Feeds";
import UpdateUserTerm from "./UpdateUserTerm";
import SurveyContainer from "./SurveyContainer";
import PrefectureContainer from "./PrefectureContainer";
import CourseSelection from "./CourseSelection";
import PaymentContainer from "./PaymentContainer";
import ManmaruMarcheSubmission from "./ManmaruMarcheSubmission";

const MainContent = () => {
  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const getInitUser = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserInfo({ id: docSnap.id, ...docSnap.data() });
      }
    };
    if (user) {
      getInitUser();
    }
  }, [user]);

  const [userPayments, setUserPayments] = useState([]); // ユーザーの支払いフェーズ

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const paymentPhases =
          userData.payments?.map((payment) => payment.paymentPhase) || [];
        setUserPayments(paymentPhases); // 支払いフェーズを設定
        // console.log("Payment phases:", paymentPhases); // デバッグ用
      }
    };

    fetchUserInfo();
  }, []);

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  // せかいむら民へはこのメッセージ
  if (userInfo?.isSekaimura) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h1>セカイムラの皆様へ</h1>
        <p>マスターマインドへようこそ</p>
        <p>
          セカイムラの皆様は、上部アバターをクリックすることでチャットが使用することが出来ます。
        </p>
        <div className="flex overflow-hidden  ">
          <div className="flex-grow  px-1 overflow-y-scroll">
            <Feeds />
          </div>
          <div className="hidden mid:block min-w-80 max-w-80 h-screen overflow-y-scroll"></div>
        </div>{" "}
      </div>
    );
  }

  // "2"が含まれていない場合の権限なしメッセージ
  if (!userPayments.includes("2")) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h1>
          現在、入金が確認できないため、MasterMind - 2025
          の使用権限がありません。
        </h1>
        <p>
          決済終了後、サイトの閲覧が可能です。以下よりご決済お願い致します。
        </p>
        <p>
          なお、銀行振込の場合、入金情報反映まで数日の時間を要する場合がございます。
        </p>
        <p>
          以前決済されたにも関わらず、本画面が出ている方は事務局までご一報ください。
          <br />
          {/* 現在のログインアカウントとは別のアカウントにて決済されている可能性がございます。 */}
        </p>
        <PaymentContainer />
      </div>
    );
  }

  return (
    <>
      <div className="flex overflow-hidden  ">
        <div className="flex-grow  px-1 overflow-y-scroll">
          {/* <UpdateUserTerm /> */}
          {/* <PurposeComponent /> */}
          {/* {user && <PurposeSetting userId={user?.uid} />} */}
          {/* <HokkaidoMunicipalitySelect /> */}
          <div className="my-2"></div>
          {/* <SurveyContainer /> */}
          {!userInfo?.isSekaimura && userPayments.includes("2") && (
            <>
              <PrefectureContainer />
              <CourseSelection userId={userInfo?.uid} />
              {/* <ManmaruMarcheSubmission /> */}
            </>
          )}

          {userInfo?.prefecture === "北海道" && (
            <>
              <HokkaidoMunicipalitySelect />
            </>
          )}

          <Announcement />
          {userInfo?.isVerified && (
            <>
              <AudioPlayer />
            </>
          )}
          <AddPostDialog />
          {userInfo?.isVerified && (
            <>
              <Feeds />
            </>
          )}
          {/* <Feed /> */}
        </div>

        {/* ToDoリストセクション */}
        <div className="hidden mid:block min-w-80 max-w-80 h-screen overflow-y-scroll">
          <RightSections />
        </div>
      </div>
    </>
  );
};

export default memo(MainContent);
