import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import waraeru from "../data/png/waraeru.png";

const idleSpeeches = [
  "たのしいね〜",
  "こんばんわー",
  "きょうもげんき？",
  "うろうろしちゃう〜",
  "きょうもいい日になるよ〜",
  "うん、だいじょうぶだよ〜",
  "ひとやすみ、しよっか",
  "にこにこ〜にこっ♪",
  "おてんきいいねぇ〜",
  "おなかすいたね〜",
  "ひとりじゃないよ〜",
  "ちょっと、深呼吸〜",
  "にっこりしてみて？",
  "がんばってるの、しってるよ〜",
  "なんとかなるって〜♪",
  "はなうたうたっちゃお〜",
  "ちいさいこと、だいじだよ〜",
  "こつこつって、かっこいい！",
  "ゆっくりでいいんだよ〜",
  "やさしいきもち、すてきだよ",
  "いま、すごくいい顔してるよ〜",
  "おいしいもんたべよ〜",
  "そのままで、いいんだよ〜",
  "ちょっとほめてあげてね〜",
  "リラックス、リラックス〜",
  "うまくいくよ〜ん",
  "そのペースでだいじょうぶ〜",
  "ほら、ちょっと元気でてきた！",
  "あなたのこと、すきだよ〜",
  "笑ってると、幸せよってくる〜",
  "そばにいるよ〜",
  "今ここで、まるごとOK〜",
];

const motionPatterns = [
  { rotate: [0, 10, -10, 0], x: 0 },
  { x: [0, 20, -20, 0], rotate: 0 },
  { scale: [1, 1.2, 1], rotate: 0 },
  { rotate: [0, 5, 0], x: [0, 10, 0] },
  { x: [0, 10, 0], rotate: [0, 2, -2, 0] },
  { rotate: [-2, 2, -2, 2, 0], scale: 1 },
  { x: [0, -10, 0], scale: [1, 1.1, 1] },
  { x: [0, 15, -15, 0], rotate: 0 },
  { x: [0, -30, 30, 0], rotate: [-5, 5, -5, 0] }, // 左右ダッシュ
  { scale: [1, 1.3, 0.9, 1], rotate: 0 }, // 膨らんで萎む
  { rotate: [0, 15, -15, 10, -10, 0], x: 0 }, // くねくね回転
  { x: [0, -5, 5, -5, 5, 0], scale: [1, 1, 1.05, 0.95, 1, 1] }, // 細かいふるえ
  { rotate: [0, 0, 360], scale: [1, 1.1, 1] }, // 一回転
  { y: [0, -10, 0, -5, 0], rotate: [0, 2, 0] }, // 軽くジャンプ
  { scale: [1, 0.8, 1.2, 1], x: [0, 10, -10, 0] }, // 横にびよーん
  { rotate: [0, 5, -5, 5, -5, 0], y: [0, -2, 2, -2, 2, 0] }, // ウィンクして上下
  { rotate: [0, -30, 0], scale: [1, 0.9, 1] }, // 右にコケそうになる
  { x: [0, 5, 10, 20, 10, 0], scale: [1, 1, 1.1, 1.2, 1, 1] }, // 右に寄ってから戻る
  { x: [0, -5, -10, -20, -10, 0], scale: [1, 1, 1.1, 1.2, 1, 1] }, // 左に寄ってから戻る
  { scale: [1, 1.05, 0.95, 1.05, 1], rotate: [0, 2, -2, 2, 0] }, // 呼吸みたいな動き

  // --- NEW! 個性派モーション ---
  { x: [0, 0, 0], scale: [1, 1.5, 1], rotate: [0, 0, 0] }, // ふくれる
  { y: [0, 0, -30, 0], rotate: [0, 0, 0, 0] }, // 大ジャンプ風
  { x: [0, 30, 0], rotate: [0, -15, 0], scale: 1 }, // ドーン前ののけぞり
  { x: [0, -30, 0], rotate: [0, 15, 0], scale: 1 }, // ドーン後ののけぞり
  { rotate: [0, 2, -2, 2, -2, 0], scale: [1, 0.95, 1.05, 1, 0.95, 1] }, // びくびく
  { rotate: [0, 5, 10, 15, 10, 0], x: 0 }, // ノリノリ右回り
  { rotate: [0, -5, -10, -15, -10, 0], x: 0 }, // ノリノリ左回り

  // --- ミックス表現（リズム感） ---
  { y: [0, -8, 0, -8, 0], scale: [1, 1.05, 1, 1.05, 1] }, // ダンス小ジャンプ
  { x: [0, 10, 0, -10, 0], scale: [1, 1, 1.05, 1, 1] }, // ステップ踏んでる感じ
];

const Waraeru = () => {
  const [index, setIndex] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [showSpeech, setShowSpeech] = useState(false);
  const [speechIndex, setSpeechIndex] = useState(0);

  const minX = -100;
  const maxX = 100;

  const randomOffsetX = () => Math.floor(Math.random() * 100 - 50);

  // モーション更新と位置移動（クリックされてなければ）
  useEffect(() => {
    const interval = setInterval(() => {
      if (!showSpeech) {
        const next = Math.floor(Math.random() * motionPatterns.length);
        setIndex(next);
        setPositionX((prev) => {
          const nextX = prev + randomOffsetX();
          return Math.max(minX, Math.min(maxX, nextX));
        });
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [showSpeech]);

  // セリフ更新（クリックされてなければ）
  useEffect(() => {
    const interval = setInterval(() => {
      if (!showSpeech) {
        const next = Math.floor(Math.random() * idleSpeeches.length);
        setSpeechIndex(next);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [showSpeech]);

  const toggleSpeech = () => {
    setShowSpeech((prev) => !prev);
  };

  return (
    <motion.div
      animate={{ x: positionX }}
      transition={{ duration: 1.5 }}
      style={{ position: "absolute", top: "40%", left: "40%" }}
    >
      <div style={{ position: "relative", display: "inline-block" }}>
        {/* 影 */}
        <motion.div
          animate={!showSpeech ? { x: motionPatterns[index]?.x ?? 0 } : {}}
          transition={{ duration: 1 }}
          style={{
            position: "absolute",
            bottom: -20,
            left: "20%",
            transform: "translateX(-50%)",
            width: 80,
            height: 14,
            background: "rgba(0,0,0,0.25)",
            borderRadius: "50%",
            filter: "blur(2px)",
            zIndex: 0,
          }}
        />

        {/* 本体 */}
        <motion.img
          src={waraeru}
          alt="わらえる君"
          style={{
            width: 120,
            cursor: "pointer",
            userSelect: "none",
            position: "relative",
            zIndex: 1,
          }}
          animate={!showSpeech ? motionPatterns[index] : {}}
          transition={{ duration: 1 }}
          onClick={toggleSpeech}
        />

        {/* 吹き出し */}
        {!showSpeech && (
          <motion.div
            animate={{
              x: motionPatterns[index]?.x ?? 0, // わらえる君のモーションに合わせて
            }}
            transition={{ duration: 1 }}
            style={{
              position: "absolute",
              bottom: "70px",
              left: "0", // ← 左端基準に
              right: "0", // ← 幅いっぱいにして
              textAlign: "center", // ← 中央に配置
              fontSize: "12px",
              whiteSpace: "nowrap",
              color: "orange",
              zIndex: 2,
              fontFamily: "'Mochiy Pop One', sans-serif",
            }}
          >
            {idleSpeeches[speechIndex]}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default Waraeru;
