import React, { useState, useEffect } from "react";
import { db, auth } from "../api/firebase";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PostManagement = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminPrivileges = async () => {
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists() || !userSnap.data().isAdmin) {
          navigate("/home");
        }
      } catch (error) {
        console.error("Error checking admin privileges:", error);
        navigate("/home");
      }
    };

    checkAdminPrivileges();
  }, [navigate]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(db, "posts");
        const postsQuery = query(postsCollection, orderBy("timestamp", "desc"));
        const snapshot = await getDocs(postsQuery);
        const postsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleCheckboxChange = async (postId, term) => {
    try {
      const postIndex = posts.findIndex((post) => post.id === postId);
      const updatedAllowedTerm = posts[postIndex].allowedTerm?.includes(term)
        ? posts[postIndex].allowedTerm.filter((t) => t !== term)
        : [...(posts[postIndex].allowedTerm || []), term];

      // Update Firestore
      const postRef = doc(db, "posts", postId);
      await updateDoc(postRef, { allowedTerm: updatedAllowedTerm });

      // Update local state
      const updatedPosts = [...posts];
      updatedPosts[postIndex].allowedTerm = updatedAllowedTerm;
      setPosts(updatedPosts);
    } catch (error) {
      console.error("Error updating allowedTerm:", error);
    }
  };

  const handleVisibilityToggle = async (postId, isVisible) => {
    try {
      const postRef = doc(db, "posts", postId);
      await updateDoc(postRef, { isVisible: !isVisible });

      // Update local state
      const updatedPosts = posts.map((post) =>
        post.id === postId ? { ...post, isVisible: !isVisible } : post
      );
      setPosts(updatedPosts);
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  if (loading) {
    return <Typography>Loading posts...</Typography>;
  }

  const truncateContent = (content) => {
    const maxLines = 2;
    const maxLength = 200;
    return content && content.length > maxLength
      ? content.slice(0, maxLength) + "..."
      : content;
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        投稿権限管理画面
      </Typography>
      <Divider style={{ marginBottom: "20px" }} />
      {posts.map((post) => (
        <div
          key={post.id}
          style={{ marginBottom: "20px" }}
          className="border shadow-md  rounded-md p-2"
        >
          <Typography variant="body2" color="textSecondary">
            作者: {post.author || "Unknown"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            投稿日時: {post.timestamp ? post.timestamp.toDate().toLocaleString() : "Unknown"}
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginTop: "10px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            内容: {truncateContent(post.content || "No content available")}
          </Typography>
          <div className="border p-2">
            <div>視聴対象</div>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              {[1, 2, 3].map((term) => (
                <FormControlLabel
                  key={term}
                  control={
                    <Checkbox
                      checked={post.allowedTerm?.includes(term) || false}
                      onChange={() => handleCheckboxChange(post.id, term)}
                    />
                  }
                  label={`${term}期`}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={post.allowedTerm?.includes("sekaiMura") || false}
                    onChange={() => handleCheckboxChange(post.id, "sekaiMura")}
                  />
                }
                label="セカイムラ"
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={post.isVisible || false}
                onChange={() => handleVisibilityToggle(post.id, post.isVisible)}
              />
            }
            label="表示"
          />
        </div>
      ))}
    </div>
  );
};

export default PostManagement;
