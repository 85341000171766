// src/components/BottomNavBar.js

import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Tooltip from "@mui/material/Tooltip";
import GroupIcon from "@mui/icons-material/Group";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatIcon from "@mui/icons-material/Chat";
import MovieIcon from "@mui/icons-material/Movie";
import StarIcon from "@mui/icons-material/Star";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const BottomNavBar2025H = ({
  value,
  onChange,
  handleMissionClick,
  user,
  topOffset,
  onMenuSelect,
  selectedChat,
}) => {
  return (
    <>
      <BottomNavigation
        showLabels
        value={value}
        onChange={onChange}
        style={{
          position: "fixed",
          top: topOffset,
          backgroundColor: "#ffffff",
          zIndex: 1000,
          maxWidth: "1152px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          height: "56px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Tooltip title="仲間" arrow>
          <BottomNavigationAction
            value={0}
            label="仲間"
            icon={<GroupIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip>
        {/* <Tooltip title="DDP" arrow>
          <BottomNavigationAction
            value={1}
            label="DDP"
            icon={<FavoriteIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip> */}
        <Tooltip title="チャット" arrow>
          <BottomNavigationAction
            value={2}
            label="チャット"
            icon={<ChatIcon style={{ fontSize: 30 }} />}
            // onClick={handleMenuOpen} // メニューを開く
          />
        </Tooltip>
        {/* <Tooltip title="12/22" arrow>
          <BottomNavigationAction
            value={3}
            label="1/13"
            icon={<MovieIcon style={{ fontSize: 30 }} />}
          />
        </Tooltip> */}
        <Tooltip title="グループ活動ガイドライン" arrow>
          <BottomNavigationAction
            value={4}
            icon={<StarIcon style={{ fontSize: 30 }} />}
            onClick={handleMissionClick}
          />
        </Tooltip>
      </BottomNavigation>
    </>
  );
};

export default BottomNavBar2025H;
